import { defineMessages } from "react-intl";

export const translations = defineMessages({
  title: {
    id: "DeveloperProfile.locationField.label",
    defaultMessage: "Location",
  },
  placeholder: {
    id: "DeveloperProfile.locationField.placeholder",
    defaultMessage: "Start typing to search (ex : Paris...)",
  },
  stubPlaceholder: {
    id: "DeveloperProfile.locationField.stubPlaceholder",
    defaultMessage: "Your location ",
  },
});
