import { defineMessages } from "react-intl";

export const translations = defineMessages({
  email: {
    id: "Banner.UserInformations.ContactInformations.Email",
    defaultMessage: "Email address",
  },
  phone: {
    id: "Banner.UserInformations.ContactInformations.Phone",
    defaultMessage: "Phone number",
  },
  name: {
    id: "Banner.UserInformations.ContactInformations.Name",
    defaultMessage: "Name",
  },
});
