import { createScreenWidthMediaQuery } from "../../../../../utils/styles/styles_utils";

export const styles = (theme) => {
  const {
    miscellaneous: { spacing },
  } = theme;
  return {
    typography: {
      maxWidth: "65%",
    },
    noLanguage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: [spacing, spacing * 8, 0],
    },
    noLanguageTypography: {
      color: "inherit",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    addButton: {
      marginTop: spacing * 4,
      marginLeft: -spacing,
    },
    [createScreenWidthMediaQuery("max-width", theme.screenSizes.small)]: {
      typography: {
        maxWidth: "80%",
        fontSize: 40,
      },
    },
  };
};
