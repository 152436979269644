export const ANIMATED_UNDERLINED_BUTTON_TRANSITIONS_PROPS = {
  initial: {
    opacity: 0,
    x: "-100%",
  },
  enter: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "100%",
  },
};
