import { defineMessages } from "react-intl";

export default defineMessages({
  language: {
    id: "Languages.editDialog.language.title",
    defaultMessage: "Language : ",
  },
  languagePlaceholder: {
    id: "Languages.editDialog.language.placeholder",
    defaultMessage: "Ex : English, Italian...",
  },
  level: {
    id: "Languages.editDialog.level.title",
    defaultMessage: "Level: {valueNode}%",
  },
});
