const DIMENSION = 75;

export const styles = {
  container: {
    display: "flex",
  },
  color: {
    height: DIMENSION,
    width: DIMENSION,
    backgroundColor: "currentColor",
  },
  tooltipPopper: {
    zIndex: 99999999999,
  },
};
