export const TITLE_PROPS = {
  default: {
    y: "-25%",
    opacity: 0,
  },
  active: {
    y: 0,
    opacity: 1,
  },
};

export const CONTENT_PROPS = {
  default: {
    y: "25%",
    opacity: 0,
  },
  active: {
    y: 0,
    opacity: 1,
  },
};
