import React, { useCallback, useContext, useMemo } from "react";
import { ProfileCard } from "../../../commons/profile_card/profile_card";
import { StudiesFront } from "./studies_front/studies_front";
import { StudiesBack } from "./studies_back/studies_back";
import {
  mapStudiesFromJsonResume,
  mapStudiesToJsonResume,
} from "./data/mapping";
import { StudiesCardEditDialog } from "./edit_dialog/studies_card_edit_dialog";
import { StudiesValidator, validateStudiesComplete } from "./data/validator";
import { DeveloperProfileContext } from "../../../../utils/context/contexts";
import { SIDES } from "../../../commons/profile_card/profile_card_side/side";
import { useMode } from "../../../hooks/use_mode";

const StudiesCardComponent = ({ variant, side }) => {
  const [mode] = useMode();
  const { data, onEdit, isEditing } = useContext(DeveloperProfileContext);
  const mappedData = useMemo(() => mapStudiesFromJsonResume(data), [data]);

  const onDialogEdited = useCallback(
    (editedData) => {
      onEdit(mapStudiesToJsonResume(editedData));
    },
    [onEdit]
  );

  const isComplete = useMemo(() => validateStudiesComplete(mappedData), [
    mappedData,
  ]);

  const currentSide = useMemo(() => {
    if (!isComplete && !isEditing) {
      return SIDES.FRONT;
    }
    return side;
  }, [side, isComplete, isEditing]);

  if (!isComplete && mode !== "edit") {
    return null;
  }

  return (
    <ProfileCard
      kind="studies"
      data={mappedData}
      isComplete={isComplete}
      isEditingProfile={isEditing}
      sides={{
        front: (props) => <StudiesFront {...props} />,
        back: (props) => <StudiesBack {...props} />,
      }}
      editDialog={{
        component: StudiesCardEditDialog,
        validationSchema: StudiesValidator,
        onEdit: onDialogEdited,
      }}
      variant={variant}
      side={currentSide}
    />
  );
};

export const StudiesCard = StudiesCardComponent;
