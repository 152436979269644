export const styles = (theme) => {
  const {
    miscellaneous: { spacing },
  } = theme;
  return {
    container: {
      color: "inherit",
      margin: 0,
      lineHeight: 1.6,
      whiteSpace: "pre-line",
      marginTop: spacing,
    },
  };
};
