export const styles = {
  dividerContainer: {
    overflow: "hidden",
  },
  divider: {
    height: 1,
    width: "100%",
    maxWidth: 100,
    backgroundColor: "#fff",
    margin: [8 * 2, 0],
  },
  contactInfosRows: {
    display: "flex",
    flexDirection: "column",
  },
};
