export const PROFILE_CARD_EDIT_BUTTON_TRANSITIONS_PROPS = {
  initial: {
    opacity: 0,
    scale: 0.75,
  },
  enter: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.75,
  },
};
