import React from "react";

import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";

import { Typography } from "@welovedevs/ui";

import { styles } from "./no_work_styles";
import { NoDataButton } from "../../../../../commons/no_data_button/no_data_button";

const useStyles = createUseStyles(styles);

const NoWorkComponent = ({ handleAddButtonClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography style={{ color: "inherit" }} variant="h4" component="h4">
        <FormattedMessage
          id="Experiences.noWork.title"
          defaultMessage="Les expériences que vous avez mené dans votre carrière seront affichées ici ."
        />
      </Typography>
      <NoDataButton
        handleAddButtonClick={handleAddButtonClick}
        classes={{
          container: classes.button,
        }}
      >
        <FormattedMessage
          id="Experiences.noWork.buttonLabel"
          defaultMessage="Ajouter une expérience"
        />
      </NoDataButton>
    </div>
  );
};

export const NoWork = NoWorkComponent;
