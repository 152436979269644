export const styles = (theme) => {
  const {
    miscellaneous: { spacing },
  } = theme;
  return {
    container: {
      marginRight: spacing * 2,
    },
  };
};
