export const styles = {
  container: {
    position: "relative",
  },
  handleInteractionsLayer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer",
  },
};
