export const styles = {
  container: {
    willChange: "transform",
    height: "100%",
    width: "100%",
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
  },
};
