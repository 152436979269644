import { defineMessages } from "react-intl";

export default defineMessages({
  permanent: {
    id: "Developer.contractType.permanent",
    defaultMessage: "Permanent",
  },
  fixedTerm: {
    id: "Developer.contractType.fixedTerm",
    defaultMessage: "Fixed Term",
  },
  internship: {
    id: "Developer.contractType.internship",
    defaultMessage: "Internship",
  },
  apprenticeship: {
    id: "Developer.contractType.apprenticeship",
    defaultMessage: "Apprenticeship",
  },
  freelance: {
    id: "Developer.contractType.freelance",
    defaultMessage: "Freelance",
  },
  unknown: {
    id: "Developer.contractType.unknown",
    defaultMessage: "Unknown",
  },
});
