import { defineMessages } from "react-intl";

export const translations = defineMessages({
  schoolName: {
    id: "Studies.editDialog.schoolName.title",
    defaultMessage: "Studies/Higher education",
  },
  schoolNamePlaceholder: {
    id: "Studies.editDialog.schoolName.placeholder",
    defaultMessage: "Epitech, Supinfo, 42, ISEN, IG2I...",
  },
  diplomaTitle: {
    id: "Studies.editDialog.diploma.title",
    defaultMessage: "Obtained diploma",
  },
  mainCourse: {
    id: "Studies.editDialog.mainCourse.title",
    defaultMessage: "Field",
  },
  diplomaDate: {
    id: "Studies.editDialog.diploma.date",
    defaultMessage: "Graduation year",
  },
  diplomaPlaceholder: {
    id: "Studies.editDialog.diploma.placeholder",
    defaultMessage: "Level : License, PhD...",
  },
  mainCoursePlaceholder: {
    id: "Studies.editDialog.diploma.mainCourse",
    defaultMessage: "Ex : IA , Géographie, médecine",
  },
});
