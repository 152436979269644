export const EXPERIENCE_CONTENT_TRANSITION_PROPS = {
  initial: {
    opacity: 0,
    x: "-20px",
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "-20px",
  },
};
