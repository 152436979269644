export default {
  fieldsContainer: {
    display: "flex",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  pickerInput: {
    display: "none !important",
  },
};
