export const styles = (theme) => {
  const {
    miscellaneous: { spacing },
  } = theme;
  return {
    container: {
      padding: [spacing * 4, spacing * 3],
      fontWeight: 600,
    },
  };
};
