import React from "react";

import { FormattedMessage } from "react-intl";

export const PALETTE_KEY_TRANSLATIONS = {
  primary: (
    <FormattedMessage id="PaletteVisual.Key.Primary" defaultMessage="Primary" />
  ),
  secondary: (
    <FormattedMessage
      id="PaletteVisual.Key.Secondary"
      defaultMessage="Secondary"
    />
  ),
  tertiary: (
    <FormattedMessage
      id="PaletteVisual.Key.Tertiary"
      defaultMessage="Tertiary"
    />
  ),
};
