export const DIVIDER_PROPS = {
  initial: {
    opacity: 0,
    translation: -25,
  },
  animate: {
    opacity: 1,
    translation: 0,
  },
};
